/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'ngx-toastr/toastr'; // regular style toast
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

html {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  font-family: 'Inter', sans-serif !important;
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="select"],
input[type="file"],
input[type="email"],
input[type="date"] {
  @apply h-[40px] focus:outline-none
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.validation-error {
  @apply text-red-500 text-xs
}

.primaryColor {
  color: #1B1A2E !important;
}

.secondaryColor {
  color: #9A9A9A !important;
}

.outlined-button {
  @apply rounded-full bg-white px-6 py-1.5 text-sm text-[#243154] ring-1 ring-[#243154] border-[#243154] hover:bg-gray-50 text-center w-[98px]
}

input ::placeholder {
  color: #121010 !important;
  accent-color: #243154 !important;
}

.app-text {
  color: #243154 !important;
}

.app-text-green {
  color: #33C29E !important
}


.app-text-blue {
  color: #033365 !important
}
.app-bg {
  background: #243154;
}

.activeText {
  //   @apply text-[#2ABFA3]
  @apply text-[#121010] 
}

.activeBg {
  // @apply bg-[#2ABFA3]
  @apply bg-[#033365]

}

.app-border {
  border: 1px solid #243154;
}

.app-theme-hyperlink {
  @apply app-text cursor-pointer
}

.loginbutton-app-bg {  
  //   border: 1px solid #33C29E;
  //   background: #33C29E;
  border: 1px solid #F00;
  border-radius: 4px;
  background: #F00;
  color: #fff;
  height: 40px;
  @apply px-3 py-2;
}

.button-app-bg {
  border-radius: 8px;
  border: 1px solid #33C29E;
  background: #33C29E;
  color: #fff;
  height: 40px;
  font-size: 0.875rem
  ;
  line-height: 1.25rem
  ;
  text-align: center;
  @apply px-4 py-2 w-[200px];
}


.button-app-bg-blue {
  border-radius: 8px;
  border: 1px solid #033365;
  background: #033365;
  color: #fff;
  height: 40px;
  font-size: 0.875rem
  ;
  line-height: 1.25rem
  ;
  text-align: center;
  @apply px-4 py-2 w-[200px];
}

.button-outlined-app-bg-blue {
  border-radius: 8px;
  border: 1px solid #033365;
  background: #fff;
  color: #033365;
  height: 40px;
  font-size: 0.875rem
  ;
  line-height: 1.25rem
  ;
  @apply px-4 py-2 w-[200px];
}

.button-outlined-app-bg {
  border-radius: 8px;
  border: 1px solid #33C29E;
  background: #fff;
  color: #33C29E;
  height: 40px;
  font-size: 0.875rem
  ;
  line-height: 1.25rem
  ;
  @apply px-4 py-2 w-[200px];
}

.login-card {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.login-input {
  @apply border border-[#E5E7EB] rounded-[6px] w-full h-[40px] px-3;
}

.textGreen {
  @apply text-[#27D674]
}

.bgGreen {
  @apply bg-[#ECFFF4]
}

.card {
  @apply bg-white py-8 px-6 rounded-2xl;
  // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

input[type=radio]:checked {
  background-color: #fff !important;
  border: #33C29E !important;
}

//-webkit-scrollbar
.section::-webkit-scrollbar {
  width: 4px;
}

.section::-webkit-scrollbar-thumb {
  background-color: #707070;
}



.red-btn-c {
  background-color: #fceeed !important;
  color: #df514c !important;
  @apply dark:bg-[#2D1E1E] dark:text-[#DF514C] font-semibold;
}

.green-btn-c {
  background-color: #eef8ee !important;
  color: #50b554 !important;
  @apply dark:bg-[#4CAF501A] dark:text-[#5B9A5D] font-semibold;
}

.blue-btn-c {
  background-color: #e7f3fe !important;
  color: #1185f7 !important;
  @apply dark:bg-[#1D242E] dark:text-[#4987EE] font-semibold;
}

.gray-btn-c {
  background-color: #f1f1f2 !important;
  color: #323232 !important;
  @apply dark:bg-[#BDBDBD1A] dark:text-[#BBBBBB] font-semibold;
}

.orange-btn-c {
  background-color: #fff5ec !important;
  color: #ff9900 !important;
  @apply dark:text-[#1a2428] dark:bg-[#1a2428] font-semibold;
}

.info-btn-c {
  background-color: #eceeff !important;
  color: #1767fd !important;
  @apply dark:text-[#2a79c2] dark:bg-[#2a79c2] font-semibold;
}

.purple-btn-c {
  background-color: #f4ebff !important;
  color: #913aff !important;
  @apply dark:text-[#844AEB] dark:bg-[#252327] font-semibold;
}

.pink-btn-c {
  background-color: #fff2fa !important;
  color: #ff00a8 !important;
  @apply dark:text-[#c53794] dark:bg-[#33242d] font-semibold;
}

select {
  @apply block w-full h-[40px] rounded-md border-0 px-3 focus:outline-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6;
}

.toast-error {
  background-color: #F00 !important;
  color: #f8f8f8 !important;
  font-size: 12px !important;
}

.toast-info {
  background-color: #4e95cb !important;
  color: #f8f8f8 !important;
  font-size: 12px !important;
}

.toast-success {
  background-color: #4ecb8a !important;
  color: #f8f8f8 !important;
  font-size: 12px !important;
}

.mandatory-field {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
  padding-left: 0.25rem
    /* 4px */
  ;
}

.required:after {
  content: "*";
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
  padding-left: 0.25rem
    /* 4px */
  ;
}

.transCard {
  @apply transform transition-transform duration-300 hover:scale-105
}

.banner-info {
  @apply bg-blue-50 text-blue-400 rounded px-4 text-sm py-4
}

.inputHead {
  @apply text-[#6E7795]
}

.transition {
  width: 200px;
  opacity: 0;
  margin-left: 0;
  animation-name: myTransition;
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  animation-fill-mode: forwards;
}

@keyframes myTransition {
  0% {
  }
  100% {
    opacity: 1;
  }
}

// .overflow-inherit{
//   overflow: inherit !important;
// }

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  
  /* Position the tooltip */
  position: absolute !important;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute !important;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
//example code to enable tooltip
// <div class="tooltip">Hover over me
//   <span class="tooltiptext">Tooltip text</span>
// </div>